// Importera nödvändiga bibliotek
import React, { useState, useEffect } from "react";

const HighScoreManager = () => {
  const [highScores, setHighScores] = useState([]);

  useEffect(() => {
    const storedScores = JSON.parse(localStorage.getItem("highScores")) || [];
    setHighScores(storedScores);
  }, []);

  const handleDelete = (index) => {
    const updatedScores = highScores.filter((_, i) => i !== index);
    setHighScores(updatedScores);
    localStorage.setItem("highScores", JSON.stringify(updatedScores));
  };

  return (
    <div style={{
      padding: "20px",
      fontFamily: "'Roboto', Arial, sans-serif",
      textAlign: "center",
      maxWidth: "600px",
      margin: "auto",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      minHeight: "100vh",
    }}>
      <h1>Hantera High Scores</h1>
      {highScores.length === 0 ? (
        <p>Det finns inga high scores att visa.</p>
      ) : (
        <ul style={{ listStyleType: "none", padding: 0 }}>
          {highScores.map((entry, index) => (
            <li
              key={index}
              style={{
                margin: "10px 0",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span>
                {index + 1}. {entry.name}: {entry.score}
              </span>
              <button
                onClick={() => handleDelete(index)}
                style={{
                  padding: "5px 10px",
                  backgroundColor: "#ff4d4d",
                  color: "white",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              >
                Radera
              </button>
            </li>
          ))}
        </ul>
      )}
      <button
        onClick={() => window.location.href = "/"}
        style={{
          marginTop: "20px",
          padding: "10px 15px",
          backgroundColor: "#007bff",
          color: "white",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
        }}
      >
        Tillbaka till Quizet
      </button>
    </div>
  );
};

export default HighScoreManager;
