import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import QuizApp from "./QuizApp"; // Importera din quizkomponent
import HighScoreManager from "./highscoremanager"; // Importera HighScoreManager-komponenten

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<QuizApp />} /> {/* Quizsidan */}
        <Route path="/highscores" element={<HighScoreManager />} /> {/* HighScoreManager */}
      </Routes>
    </Router>
  );
};

export default App;
