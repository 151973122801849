// Importera nödvändiga bibliotek
import React, { useState, useEffect } from "react";
import questions from "./questions"; // Importera frågor från en separat fil
import logo from "./ed-logga.png"; // Importera loggan

const QuizApp = () => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [score, setScore] = useState(0);
  const [isQuizFinished, setIsQuizFinished] = useState(false);
  const [userName, setUserName] = useState("");
  const [isNameSubmitted, setIsNameSubmitted] = useState(false);
  const [highScores, setHighScores] = useState([]);
  const [startTime, setStartTime] = useState(null);
  const [elapsedTime, setElapsedTime] = useState(0);

  useEffect(() => {
    const storedScores = JSON.parse(localStorage.getItem("highScores")) || [];
    setHighScores(storedScores);
  }, []);

  useEffect(() => {
    let timer;
    if (isNameSubmitted && !isQuizFinished) {
      timer = setInterval(() => {
        setElapsedTime(Math.floor((Date.now() - startTime) / 1000));
      }, 1000);
    } else {
      clearInterval(timer);
    }
    return () => clearInterval(timer);
  }, [isNameSubmitted, isQuizFinished, startTime]);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const handleNextQuestion = () => {
    if (selectedOption === questions[currentQuestionIndex].answer) {
      setScore(score + 1);
    }

    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setSelectedOption(null);
    } else {
      setIsQuizFinished(true);
      const newScore = {
        name: userName,
        score: score + (selectedOption === questions[currentQuestionIndex].answer ? 1 : 0),
        time: elapsedTime,
      };
      const updatedScores = [...highScores, newScore]
        .sort((a, b) => b.score - a.score || a.time - b.time)
        .slice(0, 5);
      setHighScores(updatedScores);
      localStorage.setItem("highScores", JSON.stringify(updatedScores));
    }
  };

  const handleRestart = () => {
    window.location.reload();
};

  const handleNameSubmit = () => {
    if (userName.trim()) {
      setIsNameSubmitted(true);
      setStartTime(Date.now());
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  return (
    <div
      style={{
        padding: "20px",
        fontFamily: "'Roboto', Arial, sans-serif",
        textAlign: "center",
        maxWidth: "600px",
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        minHeight: "100vh",
      }}
    >
      <img
        src={logo}
        alt="ED Logga"
        style={{ width: "150px", marginBottom: "20px", alignSelf: "center" }}
      />
      {!isNameSubmitted ? (
        <div>
          <h1>Välkommen till EDs AI Quiz!</h1>
          <p>Skriv ditt namn för att börja:</p>
          <input
            type="text"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
            style={{
              padding: "10px",
              fontSize: "16px",
              borderRadius: "5px",
              border: "1px solid #ccc",
              marginBottom: "10px",
              width: "80%",
            }}
          />
          <button
            onClick={handleNameSubmit}
            style={{
              padding: "10px 15px",
              backgroundColor: "#4caf50",
              color: "white",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            Börja Quizet
          </button>
        </div>
      ) : !isQuizFinished ? (
        <div>
          <h1>AI quiz</h1>
          <p>Tid: {formatTime(elapsedTime)}</p>
          <p>
            Fråga {currentQuestionIndex + 1} av {questions.length}
          </p>
          <h2>{questions[currentQuestionIndex].question}</h2>
          <div>
            {questions[currentQuestionIndex].options.map((option) => (
              <button
                key={option}
                onClick={() => handleOptionClick(option)}
                style={{
                  display: "block",
                  margin: "10px auto",
                  padding: "10px 15px",
                  backgroundColor:
                    selectedOption === option ? "#d3d3d3" : "#f0f0f0",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  cursor: "pointer",
                  maxWidth: "200px",
                }}
              >
                {option}
              </button>
            ))}
          </div>
          <button
            onClick={handleNextQuestion}
            disabled={!selectedOption}
            style={{
              marginTop: "20px",
              padding: "10px 15px",
              backgroundColor: "#4caf50",
              color: "white",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              maxWidth: "200px",
            }}
          >
            Nästa
          </button>
        </div>
      ) : (
        <div>
          <h1>Resultat</h1>
          <p>Du fick {score} av {questions.length} rätt!</p>
          <p>Tid: {formatTime(elapsedTime)}</p>
          <p>Visste du att denna webbsida togs fram av ChatGPT?</p>
          <h2>High Scores</h2>
          <ul style={{ listStyleType: "none", padding: 0 }}>
            {highScores.map((entry, index) => (
              <li key={index} style={{ margin: "10px 0" }}>
                {index + 1}. {entry.name}: {entry.score} - Tid: {formatTime(entry.time)}
              </li>
            ))}
          </ul>
          <button
            onClick={handleRestart}
            style={{
              marginTop: "20px",
              padding: "10px 15px",
              backgroundColor: "#007bff",
              color: "white",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              maxWidth: "200px",
            }}
          >
            Gör om testet
          </button>
        </div>
      )}
    </div>
  );
};

export default QuizApp;
