const questions = [
    {
      id: 1,
      question: "Vem anses vara fadern till artificiell intelligens?",
      options: ["Alan Turing", "Elon Musk", "Ada Lovelace"],
      answer: "Alan Turing",
    },
    {
      id: 2,
      question: "Vilket år skapades det första AI-programmet?",
      options: ["1951", "1984", "2001"],
      answer: "1951",
    },
    {
      id: 3,
      question: "Vad betyder AI?",
      options: ["Automatisk innovation", "Artificiell intelligens", "Avancerad information"],
      answer: "Artificiell intelligens",
    },
    {
      id: 4,
      question: "Vilket företag skapade AI-modellen ChatGPT?",
      options: ["OpenAI", "Google", "Meta"],
      answer: "OpenAI",
    },
    {
      id: 5,
      question: "Vilken av följande är en typ av maskininlärning?",
      options: ["Förstärkt inlärning", "Förbättrad programmering", "Automatiserad datainsamling"],
      answer: "Förstärkt inlärning",
    },
    {
      id: 6,
      question: "Vad är syftet med ett neuronnätverk?",
      options: ["Att simulera mänsklig hjärnaktivitet", "Att skapa nya datorer", "Att förbättra internethastighet"],
      answer: "Att simulera mänsklig hjärnaktivitet",
    },
    {
      id: 7,
      question: "Vilket av följande är en vanlig AI-applikation?",
      options: ["Ansiktsigenkänning", "Textredigering", "Filhantering"],
      answer: "Ansiktsigenkänning",
    },
    {
      id: 8,
      question: "Vad används AI-assistenter som Siri och Alexa till?",
      options: ["Att hjälpa till med vardagliga uppgifter", "Att programmera mjukvara", "Att laga mat"],
      answer: "Att hjälpa till med vardagliga uppgifter",
    },
    {
      id: 9,
      question: "Vad är ett exempel på en AI-algoritm?",
      options: ["Beslutsträd", "Sorteringsfunktion", "Filkomprimering"],
      answer: "Beslutsträd",
    },
    {
      id: 10,
      question: "Vilken term används för att beskriva när AI förbättrar sig själv över tid?",
      options: ["Självlärande", "Dataoptimering", "Automatisk övervakning"],
      answer: "Självlärande",
    },
  ];
  
  export default questions;
  